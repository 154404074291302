@import-normalize;

.App {
  text-align: center;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

.flockler-wall-item__content{
  background-color: transparent;
}

.flockler-wall-item__body,
.flockler-wall-item__showmore,
a.flockler-wall-item__profile__detail
 {
  display: none;
}

.flockler-wall-item__content {
  border: none;
  background-color: transparent;
}

a.flockler-wall-item__profile__name {
  color: #FFFFFF;
  font-weight: 400;
}

.flockler-wall-item__profile__avatar {
  border: 1px solid #979797;
  margin-right: 0.8125em;
  border-radius: 50%;
  width: inherit;
}